import React from 'react';
import { setView } from "../actions/index";
import { connect } from "react-redux";
import { config } from '../app/config';
import '../styles/Andra.scss';
import TranslationService from "../app/services/TranslationService";

const specialColors = {
    "water": [
        'rgba(0,102,255,0.8)',
        'rgba(0,102,255,0.8)',
        'rgba(0,157,255,0.8)',
        'rgba(0,157,255,0.8)',
        'rgba(0,178,255,0.8)'
    ],
    "pl-pl": [
        'rgba(220, 20, 60, 0.8)',
        'rgba(255, 255, 255, 0.8)',
        'rgba(220, 20, 60, 0.8)',
        'rgba(255, 255, 255, 0.8)',
        'rgba(255, 255, 255, 0.8)',
    ],
    "de-de": [
        'rgba(255, 204, 0, 0.8)',
        'rgba(100, 100, 100, 0.8)',
        'rgba(255, 204, 0, 0.8)',
        'rgba(100, 100, 100, 0.8)',
        'rgba(221, 0, 0, 0.8)',
    ],
    "uk-ua": [
        'rgba(0, 87, 183, 0.8)',
        'rgba(255, 221, 0, 0.8)',
        'rgba(0, 87, 183, 0.8)',
        'rgba(255, 221, 0, 0.8)',
        'rgba(255, 221, 0, 0.8)',
    ],
    "lgbt": [
        'rgba(115, 41, 130, 0.8)',
        'rgba(228, 3, 3, 0.8)',
        'rgba(255, 237, 0, 0.8)',
        'rgba(255, 140, 0, 0.8)',
        'rgba(0, 128, 38, 0.8)',
    ]
};

class Andra extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: Math.floor((Math.random() * 5000) + 2000),
            activeIcon: this.props.activeIcon !== undefined && this.props.activeIcon === true,
            colors: [
                'rgba(255, 100, 0, 0.8)',
                'rgba(255, 100, 0, 0.8)',
                'rgba(255, 131, 0, 0.8)',
                'rgba(255, 131, 0, 0.8)',
                'rgba(255, 160, 0, 0.8)'
                //KOLASA
                // 'rgba(0, 148, 66, 0.8)',
                // 'rgba(0, 99, 214, 0.8)',
                // 'rgba(255, 255, 255, 0.8)',
                // 'rgba(246, 182, 0, 0.8)',
                // 'rgba(34, 34, 34, 0.8)',
                //ESTONIA
                // 'rgba(255, 255, 255, 0.8)',
                // 'rgba(0, 114, 206, 0.8)',
                // 'rgba(255, 255, 255, 0.8)',
                // 'rgba(0, 114, 206, 0.8)',
                // 'rgba(100, 100, 100, 0.8)',
                //BARBIE
                // 'rgba(255, 22, 251, 0.8)',
                // 'rgba(255, 22, 251, 0.8)',
                // 'rgba(255, 22, 251, 0.8)',
                // 'rgba(255, 22, 251, 0.8)',
                // 'rgba(255, 22, 251, 0.8)',
            ]
        };

        let nationalDays = TranslationService.trans(this.props.language, '_national_days');
        if (nationalDays !== '_national_days' && specialColors[this.props.language] !== undefined) {
            nationalDays = nationalDays.split(',');
            let date = new Date();
            let month = ((date.getUTCMonth() + 1) < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1);
            let today =
                month + '-' +
                (date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate());
            let currentDay = date.getUTCFullYear() + '-' + today;

            if (nationalDays.indexOf(today) >= 0 || nationalDays.indexOf(currentDay) >= 0) {
                this.state.colors = specialColors[this.props.language];
            }
        }

        if (config.special_colors !== null && specialColors[config.special_colors] !== undefined) {
            this.state.colors = specialColors[config.special_colors];
        }
    }

    openMenu = () => {
        if (this.state.activeIcon) {
            this.props.setView('menu');
        }
    }

    render() {
        const style = {...this.props?.style, width: 50 * this.props.scale, height: 50 * this.props.scale};

        return (
            <div className="andra" onClick={this.openMenu}
                 style={style}>
                <div className="scene" id={"scene" + this.state.id} style={{
                    width: 50 * this.props.scale,
                    height: 50 * this.props.scale,
                    margin: (-25 * this.props.scale) + 'px 0 0 ' + (-25 * this.props.scale) + 'px'
                }}>
                    <div className="shape pyramid-1 pyr-1" style={{
                        width: 4 * this.props.scale + 'em',
                        height: 5 * this.props.scale + 'em',
                        margin: (-2.5 * this.props.scale) + 'em 0 0 ' + (-2 * this.props.scale) + 'em',
                        transform: 'translate3D(0em, ' + (2.5 * this.props.scale) + 'em, 0em) rotateX(0deg) rotateY(0deg) rotateZ(0deg)'
                    }}>
                        <div className="face-wrapper ft"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{width: 4 * this.props.scale + 'em', height: 4 * this.props.scale + 'em', backgroundColor: this.state.colors[0], boxShadow: 'inset 0 0 0 1px ' + this.state.colors[0]}}/>
                        </div>
                        <div className="face-wrapper bk"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{width: 4 * this.props.scale + 'em', height: 4 * this.props.scale + 'em', backgroundColor: this.state.colors[0], boxShadow: 'inset 0 0 0 1px ' + this.state.colors[0]}}/>
                        </div>
                        <div className="face-wrapper lt"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{width: 4 * this.props.scale + 'em', height: 4 * this.props.scale + 'em', backgroundColor: this.state.colors[0], boxShadow: 'inset 0 0 0 1px ' + this.state.colors[0]}}/>
                        </div>
                        <div className="face-wrapper rt"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{width: 4 * this.props.scale + 'em', height: 4 * this.props.scale + 'em', backgroundColor: this.state.colors[0], boxShadow: 'inset 0 0 0 1px ' + this.state.colors[0]}}/>
                        </div>
                        <div className="face bm"
                             style={{width: 4 * this.props.scale + 'em', height: 4 * this.props.scale + 'em', backgroundColor: this.state.colors[0], boxShadow: 'inset 0 0 0 1px ' + this.state.colors[0]}}/>
                    </div>

                    <div className="shape pyramid-2 pyr-2" style={{
                        width: 4 * this.props.scale + 'em',
                        height: 5 * this.props.scale + 'em',
                        margin: (-2.5 * this.props.scale) + 'em 0 0 ' + (-2 * this.props.scale) + 'em',
                        transform: 'translate3D(' + (2 * this.props.scale) + 'em, ' + (-2 * this.props.scale) + 'em, 0em) rotateX(0deg) rotateY(360deg) rotateZ(-135deg)'
                    }}>
                        <div className="face-wrapper ft"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 4 * this.props.scale + 'em',
                                     height: 4 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[1],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[1]
                                }}/>
                        </div>
                        <div className="face-wrapper bk"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 4 * this.props.scale + 'em',
                                     height: 4 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[1],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[1]
                                }}/>
                        </div>
                        <div className="face-wrapper lt"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 4 * this.props.scale + 'em',
                                     height: 4 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[1],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[1]
                                }}/>
                        </div>
                        <div className="face-wrapper rt"
                             style={{width: 4 * this.props.scale + 'em', height: 2 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 4 * this.props.scale + 'em',
                                     height: 4 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[1],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[1]
                                }}/>
                        </div>
                        <div className="face bm"
                             style={{
                                 width: 4 * this.props.scale + 'em',
                                 height: 4 * this.props.scale + 'em',
                                 backgroundColor: this.state.colors[1],
                                 boxShadow: 'inset 0 0 0 1px ' + this.state.colors[1]
                            }}/>
                    </div>

                    <div className="shape pyramid-3 pyr-3" style={{
                        width: 3.5 * this.props.scale + 'em',
                        height: 4 * this.props.scale + 'em',
                        margin: (-2 * this.props.scale) + 'em 0 0 ' + (-1.75 * this.props.scale) + 'em',
                        transform: 'translate3D(0em, 0em, ' + (2.1 * this.props.scale) + 'em) rotateX(90deg) rotateY(0deg) rotateZ(0deg)'
                    }}>
                        <div className="face-wrapper ft"
                             style={{width: 3.5 * this.props.scale + 'em', height: 1.75 * this.props.scale + 'em'}}>
                            <div className="face" style={{
                                width: 3.5 * this.props.scale + 'em',
                                height: 3.5 * this.props.scale + 'em',
                                backgroundColor: this.state.colors[2],
                                boxShadow: 'inset 0 0 0 1px ' + this.state.colors[2]
                            }}/>
                        </div>
                        <div className="face-wrapper bk"
                             style={{width: 3.5 * this.props.scale + 'em', height: 1.75 * this.props.scale + 'em'}}>
                            <div className="face" style={{
                                width: 3.5 * this.props.scale + 'em',
                                height: 3.5 * this.props.scale + 'em',
                                backgroundColor: this.state.colors[2],
                                boxShadow: 'inset 0 0 0 1px ' + this.state.colors[2]
                            }}/>
                        </div>
                        <div className="face-wrapper lt"
                             style={{width: 3.5 * this.props.scale + 'em', height: 1.75 * this.props.scale + 'em'}}>
                            <div className="face" style={{
                                width: 3.5 * this.props.scale + 'em',
                                height: 3.5 * this.props.scale + 'em',
                                backgroundColor: this.state.colors[2],
                                boxShadow: 'inset 0 0 0 1px ' + this.state.colors[2]
                            }}/>
                        </div>
                        <div className="face-wrapper rt"
                             style={{width: 3.5 * this.props.scale + 'em', height: 1.75 * this.props.scale + 'em'}}>
                            <div className="face" style={{
                                width: 3.5 * this.props.scale + 'em',
                                height: 3.5 * this.props.scale + 'em',
                                backgroundColor: this.state.colors[2],
                                boxShadow: 'inset 0 0 0 1px ' + this.state.colors[2]
                            }}/>
                        </div>
                        <div className="face bm"
                             style={{
                                 width: 3.5 * this.props.scale + 'em',
                                 height: 3.5 * this.props.scale + 'em',
                                 backgroundColor: this.state.colors[2],
                                 boxShadow: 'inset 0 0 0 1px ' + this.state.colors[2]
                            }}/>
                    </div>

                    <div className="shape pyramid-4 pyr-4" style={{
                        width: 3 * this.props.scale + 'em',
                        height: 3.5 * this.props.scale + 'em',
                        margin: (-1.75 * this.props.scale) + 'em 0 0 ' + (-1.5 * this.props.scale) + 'em',
                        transform: 'translate3D(' + (-1 * this.props.scale) + 'em, ' + (-1.5 * this.props.scale) + 'em, ' + (-1 * this.props.scale) + 'em) rotateX(0deg) rotateY(-45deg) rotateZ(135deg)'
                    }}>
                        <div className="face-wrapper ft"
                             style={{width: 3 * this.props.scale + 'em', height: 1.5 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 3 * this.props.scale + 'em',
                                     height: 3 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[3],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[3]
                                }}/>
                        </div>
                        <div className="face-wrapper bk"
                             style={{width: 3 * this.props.scale + 'em', height: 1.5 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 3 * this.props.scale + 'em',
                                     height: 3 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[3],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[3]
                                }}/>
                        </div>
                        <div className="face-wrapper lt"
                             style={{width: 3 * this.props.scale + 'em', height: 1.5 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 3 * this.props.scale + 'em',
                                     height: 3 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[3],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[3]
                                }}/>
                        </div>
                        <div className="face-wrapper rt"
                             style={{width: 3 * this.props.scale + 'em', height: 1.5 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 3 * this.props.scale + 'em',
                                     height: 3 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[3],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[3]
                                }}/>
                        </div>
                        <div className="face bm"
                             style={{
                                 width: 3 * this.props.scale + 'em',
                                 height: 3 * this.props.scale + 'em',
                                 backgroundColor: this.state.colors[3],
                                 boxShadow: 'inset 0 0 0 1px ' + this.state.colors[3]
                            }}/>
                    </div>

                    <div className="shape pyramid-5 pyr-5" style={{
                        width: 2 * this.props.scale + 'em',
                        height: 2.5 * this.props.scale + 'em',
                        margin: (-1.25 * this.props.scale) + 'em 0 0 ' + (-1 * this.props.scale) + 'em',
                        transform: 'translate3D(' + (0.5 * this.props.scale) + 'em, ' + (0.5 * this.props.scale) + 'em, ' + (-1 * this.props.scale) + 'em) rotateX(0deg) rotateY(60deg) rotateZ(-60deg)'
                    }}>
                        <div className="face-wrapper ft"
                             style={{width: 2 * this.props.scale + 'em', height: 1 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 2 * this.props.scale + 'em',
                                     height: 2 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[4],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[4]
                                }}/>
                        </div>
                        <div className="face-wrapper bk"
                             style={{width: 2 * this.props.scale + 'em', height: 1 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 2 * this.props.scale + 'em',
                                     height: 2 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[4],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[4]
                                }}/>
                        </div>
                        <div className="face-wrapper lt"
                             style={{width: 2 * this.props.scale + 'em', height: 1 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 2 * this.props.scale + 'em',
                                     height: 2 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[4],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[4]
                                }}/>
                        </div>
                        <div className="face-wrapper rt"
                             style={{width: 2 * this.props.scale + 'em', height: 1 * this.props.scale + 'em'}}>
                            <div className="face"
                                 style={{
                                     width: 2 * this.props.scale + 'em',
                                     height: 2 * this.props.scale + 'em',
                                     backgroundColor: this.state.colors[4],
                                     boxShadow: 'inset 0 0 0 1px ' + this.state.colors[4]
                                }}/>
                        </div>
                        <div className="face bm"
                             style={{
                                 width: 2 * this.props.scale + 'em',
                                 height: 2 * this.props.scale + 'em',
                                 backgroundColor: this.state.colors[4],
                                 boxShadow: 'inset 0 0 0 1px ' + this.state.colors[4]
                            }}/>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        let scene = document.getElementById('scene' + this.state.id);
        let rotateY = 0;

        var that = this;
        setInterval(function() {
            scene.style.transform = 'rotateX(-15deg) rotateY(' + rotateY + 'deg)';

            if (that.props.andra === 2) {
                rotateY += 2;
            }

            if (rotateY++ >= 360) {
                rotateY = 0;
            }
        }, 15);
    }
}

const mapStateToProps = (state) => {
    return {
        andra: state.andra,
        language: state.user === null ? TranslationService.getDefaultLanguage() : state.user.language
    }
};

export const AndraContainer = connect(mapStateToProps, { setView })(Andra);