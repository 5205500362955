import React from 'react';
import {AlarmCodeConfirmationContainer} from "./AlarmCodeConfirmation";
import axios from "axios";
import {config} from "../../../app/config";
import {connect} from "react-redux";
import {preloaderHide, preloaderShow, alert} from "../../../actions";
import TranslationService from "../../../app/services/TranslationService";

class AlarmButtonBaseClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            masterId: this.props.masterId,
            status: this.props.status,
            mode: this.props.mode,
            alarmActionAddress: null,
            callback: this.props.callback
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.status !== this.state.status || this.props.mode !== this.state.mode) {
            this.setState({status: this.props.status, mode: this.props.mode});
        }
    };

    getClass = () => {
        if (this.state.status === 1 && this.state.mode === 0) {
            return "imd-button imd-button-sm imd-border-color-green imd-text-green";
        } else if ((this.state.status === 1 && this.state.mode === 1) || (this.state.status === 1 && this.state.mode === 3)) {
            return "imd-button imd-button-sm imd-border-color-yellow imd-text-yellow";
        } else if (this.state.status === 1 && this.state.mode === 2) {
            return "imd-button imd-button-sm imd-border-color-red imd-text-red";
        } else if (this.state.status === 2) {
            return "imd-button imd-button-sm imd-border-color-blue imd-text-blue";
        }

        return "imd-button imd-button-sm imd-border-color-silver imd-text-silver";
    }

    getText = () => {
        if (this.state.status === 1 && this.state.mode === 0) {
            return TranslationService.trans(this.props.language, 'nyumba.alarm.state.active');
        } else if (this.state.status === 1 && this.state.mode === 1) {
            return TranslationService.trans(this.props.language, 'nyumba.alarm.state.waked_up');
        } else if (this.state.status === 1 && this.state.mode === 2) {
            return TranslationService.trans(this.props.language, 'nyumba.alarm.state.run');
        } else if (this.state.status === 1 && this.state.mode === 3) {
            return TranslationService.trans(this.props.language, 'nyumba.alarm.state.sleeping');
        } else if (this.state.status === 2) {
            return TranslationService.trans(this.props.language, 'nyumba.alarm.state.disabling');
        }

        return TranslationService.trans(this.props.language, 'nyumba.alarm.state.inactive');
    }

    action = () => {
        let that = this;

        if (this.state.status === 1 && this.state.mode === 0) {
            this.setState({alarmActionAddress: config.apiAddress + '/app/nyumba/master/' + this.state.masterId + '/alarm/deactivate'});

            return;
        } else if ((this.state.status === 1 && this.state.mode === 1) || (this.state.status === 1 && this.state.mode === 2)) {
            this.setState({alarmActionAddress: config.apiAddress + '/app/nyumba/master/' + this.state.masterId + '/alarm/sleep'});

            return;
        }

        axios.patch(config.apiAddress + '/app/nyumba/master/' + this.state.masterId + '/alarm/activate')
            .then(function (response) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'nyumba.alarm.actions.activate'));

                setTimeout(
                    function () {
                        that.props.callback();
                    },
                    1000
                );
            }).catch(function(response) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'nyumba.alarm.actions.activation_failed'));
            });
    }

    render() {
        return (
            <div style={{display: 'inline-block'}}>
                <button className={this.getClass()} style={{marginLeft: '13px'}} onClick={() => this.action()}>
                    Alarm: {this.getText()}
                </button>

                <AlarmCodeConfirmationContainer address={this.state.alarmActionAddress} callback={() => {this.setState({alarmActionAddress: null}); this.props.callback(); }} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
    }
};

export const AlarmButton = connect(mapStateToProps, { preloaderShow, preloaderHide, alert })(AlarmButtonBaseClass);
