import React from 'react';
import { connect } from 'react-redux';
import '../../styles/screens/main.scss';
import '../../styles/screens/auto.scss';

export class AutoScreen extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            car: {
                name: "Panda One"
            }
        }

        //console.log('System', navigator.geolocation.getCurrentPosition());
    }

    getDistanceBetweenPoints(latitude1, longitude1, latitude2, longitude2, unit = 'miles') {
        let theta = longitude1 - longitude2;
        let distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
            Math.sin(latitude1 * (Math.PI/180)) * Math.sin(latitude2 * (Math.PI/180)) +
            Math.cos(latitude1 * (Math.PI/180)) * Math.cos(latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
        );
        if (unit === 'miles') {
            return Math.round(distance, 2);
        } else if (unit === 'kilometers') {
            return Math.round(distance * 1.609344, 2);
        }
    }

    /**
     * @returns {{display: string}}
     */
    getStyle() {
        return {
            display: this.props.view === 'auto' ? 'block' : 'none'
        }
    }

    // catchTextFromMic = (text) => {
    //     this.setState({query: text, thinking: true});
    //     this.sendQuery(this.state.query);
    // }
    //
    // handleQueryTextChange = (event) => {
    //     this.setState({queryText: event.target.value});
    // };
    //
    // handleKeyDown = (event) => {
    //     if (event.key === 'Enter' && this.state.queryText !== '') {
    //         this.setState({queryText: '', query: this.state.queryText, thinking: true});
    //         this.sendQuery(this.state.queryText);
    //     }
    // };
    //
    // sendQuery = (query) => {
    //     let that = this;
    //
    //     axios.post(config.apiAddress + '/query', {query: query})
    //         .then(function (response) {
    //             that.setState({thinking: false, answer: response.data.answer});
    //         }).catch(function(error) {
    //             that.setState({thinking: false, answer: 'Przepraszam, ale nie udało mi się znaleźć odpowiedzi.'});
    //         });
    // }

    render() {
        return (
            <div className="screen" style={this.getStyle()} id="screen-auto">
                <span className='alert imd-text-red'>Tryb dostępny wyłącznie w trybie poziomym.</span>

                <div className='imd-container-fluid content'>
                    <div className='imd-row'>
                        <div className='imd-col'>
                            <h1>Witam w {this.state.car.name}</h1>
                        </div>

                        <div className='imd-col'>
                            C2
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        view: state.view
    }
};
const mapDispatchToProps = {};

export const AutoScreenContainer = connect(mapStateToProps, mapDispatchToProps)(AutoScreen);
