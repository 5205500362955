import React from 'react';

export class NotesList extends React.Component {
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            opened: false,
            activeElement: {
                id: null,
                label: 'Wybierz element'
            }
        };
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.openedElementId !== this.props.openedElementId && (this.props.openedElementId === null || this.props.openedElementId === '')) {
            this.setState({
                activeElement: {
                    id: null,
                    label: 'Wybierz element'
                }
            });
        }
    };

    /**
     * @returns {string}
     */
    listClass = () => {
        if (this.state.opened) {
            return 'selectable-list opened';
        }

        return 'selectable-list list';
    };

    /**
     * @param label
     * @param id
     */
    elementOpen = (label, id) => {
        this.setState({
            opened: false,
            activeElement: {
                id: id,
                label: label
            }
        });
        this.props.openElement(id);
    };

    /**
     * @returns {[]}
     */
    renderData = () => {
        let rows = [];

        let that = this;
        Object.keys(this.props.elements).forEach(function(key) {
            rows.push(<div className="imd-border-color-accent element"
                           onClick={() => that.elementOpen(that.props.elements[key].title, that.props.elements[key].id)}
                           key={key}
            >
                <span className="imd-text-main">
                    {that.props.elements[key].title}
                </span>
            </div>);
        });

        return rows;
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.listClass()}>
                <div className="list">
                    {this.renderData()}
                </div>

                <div className="imd-bg-80-default imd-border-color-main active-element" onClick={() => this.setState({opened: true})}>
                    <span>{this.state.activeElement.label}</span>
                </div>
            </div>
        );
    }
}
