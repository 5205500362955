import React from 'react';
import '../styles/Andra.scss';


export default class AI extends React.Component {
    render() {
        const id = Math.round(Math.random() * 1000000);
        const size = this.props.size === undefined ? '1em' : this.props.size;
        const styles = {
            width: size,
            height: size,
            border: 'none',
            position: 'static',
            stroke: 'none',
            marginRight: '-' + size
        };

        if (this.props.visible !== undefined) {
            styles.visibility = this.props.visible ? 'visible' : 'hidden';
        }

        return (
            <svg xmlns="http://www.w3.org/2000/svg"
                 style={styles}
                 viewBox="0 0 5000 5000">
                <defs>
                    <linearGradient id={'ai-gradient-1-' + id}>
                        <stop stop-color="#f6b600" offset="0%">
                            <animate attributeName="stop-color" values="#f6b600; rgb(144, 102, 255); #f6b600" dur="2s" repeatCount="indefinite" />
                        </stop>
                        <stop stop-color="#ff6400" offset="50%">
                            <animate attributeName="stop-color" values="#ff6400; #0064ff; #ff6400" dur="5s" repeatCount="indefinite" />
                        </stop>
                        <stop stop-color="#0064ff" offset="100%">
                            <animate attributeName="stop-color" values="#0064ff; #ff6400; #0064ff" dur="3s" repeatCount="indefinite" />
                        </stop>
                    </linearGradient>

                    <linearGradient id={'ai-gradient-2-' + id} gradientTransform="rotate(45)">
                        <stop stop-color="#f6b600" offset="0%">
                            <animate attributeName="stop-color" values="#f6b600; #0064ff; #f6b600" dur="5s" repeatCount="indefinite" />
                        </stop>
                        <stop stop-color="#ff6400" offset="50%">
                            <animate attributeName="stop-color" values="#ff6400; #0064ff; #ff6400" dur="3s" repeatCount="indefinite" />
                        </stop>
                        <stop stop-color="#0064ff" offset="100%">
                            <animate attributeName="stop-color" values="#0064ff; #ff6400; #0064ff" dur="2s" repeatCount="indefinite" />
                        </stop>
                    </linearGradient>
                </defs>

                <path id={'ai-path-1-' + id}
                      style={{fill: 'url(#ai-gradient-1-' + id + ')'}}
                      d="M 2000.00,0.00
                   C 2000.00,1508.00 1500.00,2000.00 0.00,2000.00
                     1504.00,2000.00 2000.00,2488.00 2000.00,4000.00
                     2000.00,2484.00 2500.00,2000.00 4000.00,2000.00
                     2500.00,2000.00 2000.00,1508.00 2000.00,0.00 Z" />
                <path id={'ai-path-2-' + id}
                      style={{fill: 'url(#ai-gradient-2-' + id + ')'}}
                      d="
                   M 4000.00,3000.00
                   C 4000.00,3780.00 3736.00,4000.00 3000.00,4000.00
                     3736.00,4000.00 4000.00,4284.00 4000.00,5000.00
                     4000.00,4284.00 4232.00,4000.00 5000.00,4000.00
                     4228.00,4000.00 4000.00,3780.00 4000.00,3000.00 Z" />
            </svg>
        );
    }
}
