import React from 'react';
import '../../styles/backgrounds/default.scss';

function Background() {
    return (
        <div id="background">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
        </div>
    );
}

export default Background;
