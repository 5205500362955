import React from 'react';
import ScreenComponent from './ScreenComponent';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert, login, userRefresh, setView } from "../../actions";
import { config } from "../../app/config";
import axios from "axios";
import '../../styles/screens/calendars.scss';
import TranslationService from "../../app/services/TranslationService";
import {CalendarsDialog} from "./calendar/CalendarsDialog";
import {EventDialog} from "./calendar/EventDialog";
import {NewCalendarDialog} from "./calendar/NewCalendarDialog";
import {Confirmation} from "../Confirmation";


export class CalendarsScreen extends ScreenComponent {

    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            data: [],
            event: null,
            settingsDialog: false,
            addDialog: false,
            delete_calendar: null
        };
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadData();
        }
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'calendars';
    }

    /**
     * Load data
     */
    loadData = (month, year) => {
        this.props.preloaderShow();
        let that = this;

        that.setState({data: []});
        axios.get(config.apiAddress + '/app/calendar/agenda')
            .then(function (response) {
                that.setState({data: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert(TranslationService.trans(that.props.language, 'calendars.data_loading_error'));
                that.props.preloaderHide();
            });
    };

    renderAgenda = () => {
        let rows = [];

        let that = this;
        Object.keys(this.state.data).forEach(function(utcDate) {
            let date = new Date(Date.parse(utcDate));

            let month= date.getMonth() + 1;

            rows.push(<div key={utcDate}>
                <h2 className="imd-text-accent">
                    {TranslationService.trans(that.props.language, '_date_format', {'%d': date.getDate(), '%m': (month < 10 ? '0' + month : month), '%Y': date.getFullYear()})}
                </h2>
                {
                    that.state.data[utcDate].length === 0 ?
                    <span className="imd-text-accent">{TranslationService.trans(that.props.language, 'calendars.empty_day')}</span> :
                    that.renderDay(that.state.data[utcDate])
                }
            </div>);
        });

        return rows;
    }

    renderDay = (events) => {
        let rows = [];

        let that = this;
        //all day
        events.forEach(function(event) {
            if (event.length < 1440) {
                return;
            }

            rows.push(<div key={event.id} className={'event imd-border-color-' + event.color + ' imd-text-' + event.color} onClick={() => that.showEvent(event)}>
                {event.description}
            </div>);
        });

        if (rows.length > 0) {
            rows.push(<hr key='hr' />);
        }

        //normal
        events.forEach(function(event) {
            if (event.length === 1440) {
                return;
            }

            let eventTime = new Date(event.time * 1000);

            let eventHours = eventTime.getHours() < 10 ? '0' + eventTime.getHours() : eventTime.getHours();
            let eventMinutes = eventTime.getMinutes() < 10 ? '0' + eventTime.getMinutes() : eventTime.getMinutes();

            rows.push(<div key={event.id} className={'event imd-border-color-' + event.color + ' imd-bg-80-' + event.color + ' imd-text-' + event.color} onClick={() => that.showEvent(event)}>
                <strong>{eventHours}:{eventMinutes}</strong>&nbsp;
                {event.description}
            </div>);
        });

        return rows;
    }

    showEvent = (event) => {
        this.setState({event: event});
    }

    removedCalendar = (status) => {
        if (status === 'success') {
            this.props.alert(TranslationService.trans(this.props.language, 'calendars.subscribed_calendars.removed.success'));
        } else if (status === 'error') {
            this.props.alert(TranslationService.trans(this.props.language, 'calendars.subscribed_calendars.removed.error'));
        }

        this.setState({delete_calendar: null, settingsDialog: false});

        if (status !== 'cancel') {
            let that = this;
            setTimeout(function() {
                that.loadData();
            }, 500);
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="screen" style={this.getStyle()} id="screen-calendars">
                <div className="content">
                    <button className="imd-button imd-button-outline imd-border-color-main imd-text-main"
                        onClick={(e) => this.setState({addDialog: true})}
                        style={{float: 'right', marginLeft: '7px'}}
                    >
                        <span className="imd-icon imd-icon-plus" />
                    </button>

                    <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent"
                        onClick={(e) => this.setState({settingsDialog: true})}
                        style={{float: 'right'}}
                    >
                        <span className="imd-icon imd-icon-cogs" />
                    </button>

                    <h1>{TranslationService.trans(this.props.language, 'menu.calendars')}</h1>
                    
                    {this.renderAgenda()}

                    <EventDialog event={this.state.event} close={() => this.showEvent(null)} />

                    <CalendarsDialog
                        opened={this.state.settingsDialog}
                        close={() => this.setState({settingsDialog: false})}
                        delete={(address) => this.setState({delete_calendar: address})}
                    />

                    <NewCalendarDialog opened={this.state.addDialog} close={() => this.setState({addDialog: false})} />

                    <Confirmation
                        address={this.state.delete_calendar}
                        type="system"
                        onSuccess={() => this.removedCalendar('success')}
                        onError={() => this.removedCalendar('error')}
                        onCancel={() => this.removedCalendar('cancel')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user,
        view: state.view,
        language: TranslationService.getLanguage(state.user)
    }
};
const mapDispatchToProps = { preloaderShow, preloaderHide, alert, login, userRefresh, setView };

export const CalendarsScreenContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarsScreen);
