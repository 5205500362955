import React from 'react';
import '../../../styles/screens/recipes.scss';
import TranslationService from "../../../app/services/TranslationService";


export class RecipesList extends React.Component {
    /**
     * @returns {*}
     */
    mainStyle = () => {
        if (this.props.subscreen === 'list') {
            return {display: 'block'}
        }

        return {display: 'none'}
    };

    /**
     * @returns {[]}
     */
    renderData = () => {
        let rows = [];

        let headers = [];
        let recipes = this.props.recipes;
        recipes.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }

            if (a.name < b.name) {
                return -1
            }

            return 0;
        });

        let that = this;
        Object.keys(recipes).forEach(function(key) {
            let header = that.props.recipes[key].name[0].toUpperCase();

            if (headers.indexOf(header) === -1) {
                headers.push(header);

                rows.push(<div>
                    <h2 className="imd-text-accent">{header}</h2>
                </div>);
            }

            rows.push(<div className="imd-shadow recipe" key={key}>
                <span className="imd-border-color-accent imd-text-main"
                    onClick={() => that.props.openRecipe(that.props.recipes[key].id)}
                >
                    {that.props.recipes[key].name}
                </span>
            </div>);
        });

        return rows;
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div style={this.mainStyle()}>
                <button
                    className="imd-button imd-button-outline imd-border-color-main imd-text-main"
                    onClick={() => this.props.openSubscreen('edit', true)}
                >
                    {TranslationService.trans(this.props.lang, 'recipes.new_recipe')}
                </button>

                <div className={'list'}>
                    {this.renderData()}
                </div>
            </div>
        );
    }
}
