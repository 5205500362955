export const andra = (state = 1, action) => {
    switch (action.type) {
        case 'ANDRA_STATE_NORMAL':
            return 1;
        case 'ANDRA_STATE_THINKING':
            return 2;
        default:
            return state
    }
};
