import React from 'react';
import axios from 'axios';
import { preloaderShow, preloaderHide } from '../../actions';
import { connect } from "react-redux";
import '../../styles/elements/preloader.scss';
import TranslationService from "../../app/services/TranslationService";


class WebhookActionClass extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            types: ['SMS'],
            type: 'sms',
            content: ''
        }
    }

    /**
     * @returns {*}
     */
    getClassName = () => {
        if (this.props.address !== null) {
            return 'imd-dialog imd-bg-transparent-black opened';
        }

        return 'imd-dialog imd-bg-transparent-black';
    };

    action = () => {
        let that = this;

        this.props.preloaderShow();
        axios.post(this.props.address, {type: this.state.type, content: this.state.content})
            .then(function (response) {
                that.props.onSuccess(response);
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.onError(error);
                that.props.preloaderHide();
            });
    };

    cancel = () => {
        if (typeof this.props.onCancel !== 'undefined') {
            this.props.onCancel();
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={this.getClassName()}>
                <div className="imd-dialog-body imd-bg-80-default imd-text-accent">
                    <header>
                        {TranslationService.trans(this.props.language, 'integrations.webhooks.new_action')}
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.cancel()} />
                    </header>

                    <div className="imd-dialog-content">
                        <div className="imd-container">
                            <div className="imd-row">
                                <div className="imd-col-xs-12 imd-col-md-3">
                                    <select
                                        className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent"
                                        onChange={(e) => this.setState({type: e.target.value})}
                                        value={this.state.type}
                                    >
                                        {this.state.types.map((type, i) => <option value={type} key={i}>{type}</option>)}
                                    </select>
                                </div>

                                <div className="imd-col-xs-12 imd-col-md-9">
                                    <input
                                        placeholder={TranslationService.trans(this.props.language, 'common.content')}
                                        className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent"
                                        required={true}
                                        onChange={(e) => this.setState({content: e.target.value})}
                                        value={this.state.content}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => this.action()}>
                            {TranslationService.trans(this.props.language, 'common.add')}
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        language: TranslationService.getLanguage(state.user)
    }
};

export const WebhookActionDialog = connect(mapStateToProps, {preloaderShow, preloaderHide})(WebhookActionClass);
