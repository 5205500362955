import React from 'react';
import axios from 'axios';
import { config } from '../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../actions';
import '../../styles/elements/screen.scss';
import '../../styles/screens/integrations.scss';
import TranslationService from "../../app/services/TranslationService";
import { Confirmation } from "../Confirmation";
import { WebhookActionDialog } from "./WebhookActionDialog";


class WebhooksClass extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            available_webhooks: [
                'nyumba.online',
                'nyumba.offline',
                'nyumba.device.offline',
                'user.webhook.one',
                'user.webhook.two',
                'user.webhook.three'
            ],
            webhooks: [],
            add: null,
            remove: null
        }
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== 'integrations' && this.props.view === 'integrations') {
        // if (prevProps.active !== this.props.active) {
            this.loadWebhooks();
        }
    };

    loadWebhooks = () => {
        this.props.preloaderShow()

        let that = this;
        axios.get(config.apiAddress + '/integrations/webhooks')
            .then(function (response) {
                that.setState({webhooks: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(this.props.language, 'common.actions.failed_to_load_data'));
            });
    }

    renderWebhooks = () => {
        let rows = [];

        let that = this;
        //all day
        this.state.available_webhooks.forEach(function(webhookName) {
            rows.push(<tr key={webhookName}>
                <td>{webhookName}</td>
                <td>
                    {that.renderActions(webhookName)}
                    <button className="imd-button imd-button-sm imd-button-outline imd-border-color-main imd-text-main" onClick={() => that.setState({add: config.apiAddress + '/integrations/webhooks/' + webhookName})}>
                        {TranslationService.trans(that.props.language, 'common.add')}
                    </button>
                </td>
            </tr>);
        });

        return rows;
    }

    renderActions = (webhookName) => {
        let actions = [];

        let that = this;
        this.state.webhooks.forEach(function(webhook) {
            if (webhook.type === webhookName) {
                webhook.actions.forEach(function(action, key) {
                    actions.push(<div
                        key={key}
                        className="action imd-label imd-bg-main imd-text-white"
                        onClick={() => that.removeAction(webhook.type, key)}
                        title={action.content}
                    >
                        <span className={that.getIconName(action.type)} />&nbsp;
                        {action.type}
                    </div>);
                });
            }
        });

        return actions;
    }

    getIconName = (type) => {
        switch (type) {
            case 'sms':
                return 'imd-icon imd-icon-mail4';

            case 'test':
                return 'imd-icon imd-icon-embed2';

            default:
                return 'imd-icon imd-icon-terminal'
        }
    }

    removeAction = (webhook, index) => {
        this.setState({remove: config.apiAddress + '/integrations/webhooks/' + webhook + '/' + index});
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={'tab imd-text-accent' + (this.props.active ? ' active' : null)}>
                <table className="imd-table imd-table-bordered">
                    <tr>
                        <th>{TranslationService.trans(this.props.language, 'integrations.webhooks.name')}</th>
                        <th>{TranslationService.trans(this.props.language, 'integrations.webhooks.actions')}</th>
                    </tr>

                    {this.renderWebhooks()}

                    <Confirmation
                        address={this.state.remove}
                        onCancel={() => this.setState({remove: null})}
                        onSuccess={() => {this.setState({remove: null}); this.loadWebhooks()}}
                        onError={() => this.setState({remove: null})}
                    />

                    <WebhookActionDialog
                        address={this.state.add}
                        onCancel={() => this.setState({add: null})}
                        onSuccess={() => {this.setState({add: null}); this.loadWebhooks()}}
                        onError={() => this.setState({add: null})}
                    />
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view,
        language: TranslationService.getLanguage(state.user)
    }
};

export const WebhooksTab = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(WebhooksClass);
