import React from 'react';
import axios from 'axios';
import { config } from '../../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../../actions';
import '../../../styles/elements/screens/finance.scss';


export class FinanceSettings extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            categories: [],
            newCategory: ''
        }
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.loaded && this.props.view === 'finance') {
            this.loadCategories();
        }
    };

    /**
     * Load events for week
     */
    loadCategories = () => {
        this.props.preloaderShow();
        let that = this;

        axios.get(config.apiAddress + '/app/finance/categories')
            .then(function (response) {
                that.setState({categories: response.data, loaded: true});
                that.props.preloaderHide();
            }).catch(function(error) {
            that.props.preloaderHide();
            that.props.alert('Nie udało się załadować ustawień');
        });
    };

    /**
     * @returns {[]}
     */
    renderCategories = () => {
        let categories = [];

        let that = this;
        Object.keys(this.state.categories).forEach(function(key) {
            if (that.state.categories[key] === 'limit') {
                return;
            }

            categories.push(<span className="imd-label imd-bg-80-main imd-text-white finance-category" key={that.state.categories[key]}>
                {that.props.elementName(that.state.categories[key])}
                <span className="imd-icon imd-icon-cross" onClick={() => that.removeCategory(that.state.categories[key])} />
            </span>);
        });

        return categories;
    };

    /**
     * @param event
     */
    handleNewCategoryChange = (event) => {
        this.setState({newCategory: event.target.value});
    };

    /**
     * Add category
     */
    addCategory = () => {
        let categories = this.state.categories;
        let newCategory = this.state.newCategory;

        if (newCategory !== '') {
            categories.push(newCategory);
        }

        this.setState({categories: categories, newCategory: ''});
    };

    /**
     * Remove category
     */
    removeCategory = (name) => {
        let categories = this.state.categories;
        let newCategories = [];

        for (let x = 0; x < categories.length; x++) {
            if (categories[x] !== name) {
                newCategories.push(categories[x]);
            }
        }

        this.setState({categories: newCategories});
    };

    /**
     * Load events for week
     */
    saveEvent = () => {
        this.props.preloaderShow();
        let that = this;

        axios.put(config.apiAddress + '/app/finance/categories', that.state.categories)
            .then(function (response) {
                //I'm not hide poreloader because it will be hidden by FinanceScreen Container after categories will be reloaded.
                that.props.alert('Zmiany zostały zapisane');
                that.props.saveSettings();
                that.setState({loaded: false});
            }).catch(function(error) {
            that.props.preloaderHide();
            that.props.alert('Nie udało się zapisać zmian');
        });
    };

    /**
     * @returns {*}
     */
    isOpen = () => {
        return (this.props.show ? ' opened' : null);
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={"imd-dialog imd-bg-transparent-black" + this.isOpen()}>
                <div className="imd-dialog-body imd-bg-80-default imd-text-accent">
                    <header className="imd-text-contrast">
                        Ustawienia
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => {this.props.closeEvent(); this.setState({loaded: false});}} />
                    </header>

                    <div className="imd-dialog-content">
                        <div className="imd-form-control imd-bg imd-bg-80-default imd-border-color-main">
                            {this.renderCategories()}
                        </div>

                        <div className="imd-form-group">
                            <input className="imd-text-main imd-bg-80-default" placeholder="Nowa kategoria" value={this.state.newCategory} onChange={this.handleNewCategoryChange} />
                            <button className="imd-bg-80-main imd-text-white" onClick={() => this.addCategory()}>
                                Dodaj
                            </button>
                            <div className="imd-decorator imd-bg-main" />
                        </div>
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => {this.props.closeEvent(); this.setState({loaded: false});}}>
                            Anuluj
                        </button>
                        <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={this.saveEvent}>
                            Zapisz
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view
    }
};

export const FinanceSettingsContainer = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(FinanceSettings);
