import React from 'react';
import { alert } from '../actions';
import { connect } from "react-redux";
import '../styles/voice.scss';
import axios from "axios";
import { config } from "../app/config";
import TranslationService from "../app/services/TranslationService";

export class Voice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animate: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.text !== this.props.text) {
            this.say();
        }
    }

    say = () => {
        if (localStorage.getItem('voice') !== 'enabled' || this.props.text == null) {
            return;
        }

        let that = this;
        axios.post(config.apiAddress + '/speech?lang=' + TranslationService.getLanguage(this.props.user), {text: this.props.text})
            .then(function (response) {
                var audio = document.getElementById('andra-speech');
                var source = document.getElementById('andra-speech-mp3');

                source.src = config.apiAddress + '/voice/' + response.data.file;
                if (TranslationService.getLanguage(that.props.user) !== 'pl-pl') {
                    source.src = config.apiAddress + '/voice/' + TranslationService.getLanguage(that.props.user) + '/' + response.data.file;
                }

                that.setState({animate: true});
                audio.load();
                audio.play();
                audio.onended = function() {
                    that.setState({animate: false});
                };
            }).catch(function(error) {
            });
    }

    getVoiceClass = () => {
        if (this.state.animate) {
            return "boxContainer active";
        }

        return "boxContainer";
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={"imd-text-white"} style={{display: 'none'}}>
                <div className={this.getVoiceClass()}>
                    <div className="box box1"></div>
                    <div className="box box2"></div>
                    <div className="box box3"></div>
                    <div className="box box4"></div>
                    <div className="box box5"></div>
                </div>

                <audio id="andra-speech">
                    <source id="andra-speech-mp3" type="audio/mpeg" />
                </audio>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        text: state.voice
    }
};

export const VoiceContainer = connect(mapStateToProps, { alert })(Voice);
