import React from 'react';
import {connect} from "react-redux";
import { config } from "../app/config";
import {preloaderHide, preloaderShow, alert, refreshStorage} from "../actions";
import TranslationService from "../app/services/TranslationService";
import '../styles/language.scss';
import axios from "axios";

const buttonSize = '40px';

class LanguageComponentClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        };
    };

    changeLanguage = (lang) => {
        if (this.props.user === null) {
            localStorage.setItem('language', lang);
            this.props.refreshStorage();

            return;
        }

        this.props.preloaderShow();
        let that = this;
        axios.put(config.apiAddress + '/settings/language', {language: lang})
            .then(function (response) {
                that.props.alert(TranslationService.trans(that.props.language, 'settings.language.actions.success'));
                localStorage.setItem('language', lang);
                that.props.refreshStorage();
                that.props.preloaderHide();
            }).catch(function(response) {
                that.props.alert(TranslationService.trans(that.props.language, 'settings.language.actions.failed'));
                that.props.preloaderHide();
            });
    }

    render() {
        return (
            <div className="language" style={{display: 'inline-block'}}>
                <div className={"flag imd-bg-gray imd-text-50-gray"} style={{width: buttonSize, height: buttonSize, borderRadius: '50px', cursor: 'pointer', textAlign: 'center', fontSize: '25px'}}>
                    <img
                        style={{width: buttonSize, height: buttonSize, borderRadius: '50px'}}
                        src={"images/flags/" + TranslationService.trans(this.props.language, '_code') + ".png"}
                        alt={TranslationService.getLanguageAvatar(this.props.language)}
                        onClick={() => this.setState({showMenu: !this.state.showMenu})}
                    />
                </div>

                <div className="submenu imd-shadow imd-bg-default" style={{display: this.state.showMenu ? 'block' : 'none'}}>
                    <ul>
                        <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('pl-pl'); this.setState({showMenu: !this.state.showMenu})}}>Polski</li>
                        {/*<li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('et-ee'); this.setState({showMenu: !this.state.showMenu})}}>Eesti (In progress)</li>*/}
                        <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('uk-ua'); this.setState({showMenu: !this.state.showMenu})}}>Українська</li>
                        <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('en'); this.setState({showMenu: !this.state.showMenu})}}>English</li>
                        {config.env === 'dev' ? <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('de-de'); this.setState({showMenu: !this.state.showMenu})}}>Deutch (unofficial)</li> : ''}
                        {config.env === 'dev' ? <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('kk-kz'); this.setState({showMenu: !this.state.showMenu})}}>KK-KZ (unofficial)</li> : ''}
                        {config.env === 'dev' ? <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('jp-jp'); this.setState({showMenu: !this.state.showMenu})}}>日本語 (unofficial)</li> : ''}
                        {config.env === 'dev' ? <li className={"imd-text-contrast"} onClick={() => {this.changeLanguage('ar'); this.setState({showMenu: !this.state.showMenu})}}>عربي (unofficial)</li> : ''}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
    }
};

export const LanguageComponent = connect(mapStateToProps, { preloaderShow, preloaderHide, alert, refreshStorage })(LanguageComponentClass);
