export const kk_kz = {
    "_code": "kk",
    "_name": "қазақ",
    "_direction": "ltr",
    "_welcome": "\u0421\u04d9\u043b\u0435\u043c %message",
    "common": {
        "save": "\u0421\u0430\u049b\u0442\u0430\u0443",
        "cancel": "\u0411\u043e\u043b\u0434\u044b\u0440\u043c\u0430\u0443",
        "delete": "\u0416\u043e\u044e",
        "ok": "\u0416\u0410\u0420\u0410\u0419\u0414\u042b \u041c\u0410",
        "actions": {"save_failed": "\u04e8\u0437\u0433\u0435\u0440\u0456\u0441\u0442\u0435\u0440\u0434\u0456 \u0441\u0430\u049b\u0442\u0430\u0443 \u043c\u04af\u043c\u043a\u0456\u043d \u0435\u043c\u0435\u0441"}
    },
    "login": {
        "imid_button": "IMid \u0430\u0440\u049b\u044b\u043b\u044b \u043a\u0456\u0440\u0456\u04a3\u0456\u0437",
        "license": "\u041b\u0438\u0446\u0435\u043d\u0437\u0438\u044f",
        "actions": {
            "failed": "\u041b\u043e\u0433\u0438\u043d \u043a\u0456\u0440\u043c\u0435\u0434\u0456",
            "relogin_required": "\u0416\u04af\u0439\u0435\u0433\u0435 \u043a\u0456\u0440\u0443 \u049b\u0430\u0436\u0435\u0442"
        }
    },
    "main": {
        "how_can_i_help_you": "\u041c\u0435\u043d \u0441\u0456\u0437\u0433\u0435 \u049b\u0430\u043b\u0430\u0439 \u043a\u04e9\u043c\u0435\u043a\u0442\u0435\u0441\u0435 \u0430\u043b\u0430\u043c\u044b\u043d?",
        "image_answer": "\u0413\u0440\u0430\u0444\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u0430\u0443\u0430\u043f",
        "actions": {"query_failed": "\u04e8\u043a\u0456\u043d\u0456\u0448\u043a\u0435 \u043e\u0440\u0430\u0439, \u043c\u0435\u043d \u0436\u0430\u0443\u0430\u043f \u0442\u0430\u0431\u0430 \u0430\u043b\u043c\u0430\u0439\u043c\u044b\u043d."}
    },
    "menu": {"home": "\u04ae\u0439", "settings": "\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u043b\u0435\u0440"},
    "settings": {
        "tabs": {
            "account": "\u0415\u0441\u0435\u043f\u0442\u0456\u043a \u0436\u0430\u0437\u0431\u0430",
            "devices": "\u049a\u04b1\u0440\u044b\u043b\u0493\u044b\u043b\u0430\u0440",
            "app": "\u049a\u043e\u043b\u0434\u0430\u043d\u0431\u0430",
            "license": "\u041b\u0438\u0446\u0435\u043d\u0437\u0438\u044f"
        },
        "account": {
            "full_name": "\u0422\u043e\u043b\u044b\u049b \u0430\u0442\u044b",
            "email_address": "\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0434\u044b\u049b \u043f\u043e\u0448\u0442\u0430\u043d\u044b\u04a3 \u0430\u0434\u0440\u0435\u0441\u0456",
            "logout": "\u0428\u044b\u0493\u0443",
            "andra": {
                "account": "\u0410\u043d\u0434\u0440\u0430 \u0442\u0456\u0440\u043a\u0435\u043b\u0433\u0456\u0441\u0456",
                "device": "\u049a\u04b1\u0440\u044b\u043b\u0493\u044b",
                "device_name": "\u049a\u04b1\u0440\u044b\u043b\u0493\u044b\u043d\u044b\u04a3 \u0430\u0442\u044b"
            },
            "actions": {"logout": "\u0421\u04d9\u0442\u0442\u0456 \u0448\u044b\u0493\u0443"}
        },
        "devices": {
            "connected_devices": "\u049a\u043e\u0441\u044b\u043b\u0493\u0430\u043d \u049b\u04b1\u0440\u044b\u043b\u0493\u044b\u043b\u0430\u0440",
            "device_name": "\u049a\u04b1\u0440\u044b\u043b\u0493\u044b\u043d\u044b\u04a3 \u0430\u0442\u044b",
            "device_id": "Id",
            "options": "\u041e\u043f\u0446\u0438\u044f\u043b\u0430\u0440",
            "change_name": "\u0410\u0442\u044b\u043d \u04e9\u0437\u0433\u0435\u0440\u0442\u0443",
            "actions": {
                "device_removed": "\u049a\u04b1\u0440\u044b\u043b\u0493\u044b \u0436\u043e\u0439\u044b\u043b\u0434\u044b",
                "device_remove_failed": "\u049a\u04b1\u0440\u044b\u043b\u0493\u044b \u0436\u043e\u0439\u044b\u043b\u043c\u0430\u0434\u044b",
                "device_saved": "\u0421\u0430\u049b\u0442\u0430\u043b\u0493\u0430\u043d \u04e9\u0437\u0433\u0435\u0440\u0442\u0443\u043b\u0435\u0440"
            }
        },
        "app": {
            "settings": "\u049a\u043e\u043b\u0434\u0430\u043d\u0431\u0430 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u043b\u0435\u0440\u0456",
            "enable_voice": "\u0414\u0430\u0443\u044b\u0441\u0442\u044b \u043f\u0430\u0439\u0434\u0430\u043b\u0430\u043d\u044b\u04a3\u044b\u0437",
            "disable_voice": "\u0414\u0430\u0443\u044b\u0441\u0442\u044b \u04e9\u0448\u0456\u0440\u0443"
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "\u0411\u0415\u041b\u0413\u0406\u0421\u0406\u0417",
            "home": "\u04ae\u0439\u0434\u0435",
            "away": "\u0410\u043b\u044b\u0441\u0442\u0430",
            "manual": "\u041d\u04b1\u0441\u049b\u0430\u0443\u043b\u044b\u049b",
            "silence": "\u0422\u044b\u043d\u044b\u0448\u0442\u044b\u049b"
        },
        "alarm": {
            "enter_code": "\u0414\u0430\u0431\u044b\u043b \u043a\u043e\u0434\u044b\u043d \u0435\u043d\u0433\u0456\u0437\u0456\u04a3\u0456\u0437",
            "state": {
                "active": "\u0411\u0435\u043b\u0441\u0435\u043d\u0434\u0456",
                "waked_up": "\u041e\u044f\u043d\u0434\u044b",
                "run": "\u0416\u04af\u0433\u0456\u0440\u0443",
                "sleeping": "\u04b0\u0439\u044b\u049b\u0442\u0430\u0443...",
                "disabling": "\u04e8\u0448\u0456\u0440\u0443\u0434\u0435...",
                "inactive": "\u0411\u0435\u043b\u0441\u0435\u043d\u0434\u0456 \u0435\u043c\u0435\u0441"
            },
            "actions": {
                "activate": "\u0414\u0430\u0431\u044b\u043b\u0434\u044b \u0456\u0441\u043a\u0435 \u049b\u043e\u0441\u0443 \u043f\u04d9\u0440\u043c\u0435\u043d\u0456 \u0436\u0456\u0431\u0435\u0440\u0456\u043b\u0434\u0456",
                "activation_failed": "\u0416\u04af\u0439\u0435 \u0434\u0430\u0431\u044b\u043b\u0434\u044b \u0456\u0441\u043a\u0435 \u049b\u043e\u0441\u0430 \u0430\u043b\u043c\u0430\u0439\u0434\u044b",
                "send_command": "\u0414\u0430\u0431\u044b\u043b\u0493\u0430 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u0436\u0456\u0431\u0435\u0440\u0456\u043b\u0434\u0456",
                "send_command_failed": "\u041f\u04d9\u0440\u043c\u0435\u043d\u0434\u0456 \u0436\u0456\u0431\u0435\u0440\u0443 \u0441\u04d9\u0442\u0441\u0456\u0437 \u0430\u044f\u049b\u0442\u0430\u043b\u0434\u044b"
            }
        },
        "actions": {
            "send_command": "\u041a\u043e\u043c\u0430\u043d\u0434\u0430 \u0436\u0456\u0431\u0435\u0440\u0456\u043b\u0434\u0456",
            "data_failed": "\u0414\u0435\u0440\u0435\u043a\u0442\u0435\u0440\u0434\u0456 \u0436\u04af\u043a\u0442\u0435\u0443 \u043c\u04af\u043c\u043a\u0456\u043d \u0435\u043c\u0435\u0441",
            "send_failed": "\u0414\u0435\u0440\u0435\u043a\u0442\u0435\u0440\u0434\u0456 \u0436\u0456\u0431\u0435\u0440\u0443 \u043c\u04af\u043c\u043a\u0456\u043d \u0435\u043c\u0435\u0441"
        }
    }
}