import React from 'react';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../../actions';
import '../../../styles/elements/screens/finance.scss';
import TranslationService from "../../../app/services/TranslationService";
import axios from "axios";
import {config} from "../../../app/config";


class CalendarsDialogClass extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.opened && prevProps.opened !== this.props.opened) {
            this.loadData();
        }
    };

    loadData = () => {
        this.props.preloaderShow();
        let that = this;

        that.setState({data: []});
        axios.get(config.apiAddress + '/app/calendar/calendars')
            .then(function (response) {
                that.setState({data: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert(TranslationService.trans(that.props.language, 'calendars.data_loading_error'));
                that.props.preloaderHide();
            });
    }

    renderCalendars = () => {
        let rows = [];

        let that = this;
        this.state.data.forEach(function(calendar, key) {
            rows.push(<div key={key}>
                {that.props.user.subscription !== 'standard' ?
                    <span className={'imd-icon imd-icon-bell imd-text-' + (calendar.reminders ? 'green' : 'red')}
                          style={{float: 'right', cursor: 'pointer'}}
                          onClick={() => that.changeRemindersSettings(key)}
                    />
                    : null
                }
                <div className={'calendar-color imd-bg-' + calendar.color} />
                <strong>{calendar.name}</strong><br /><br />
                <span title={calendar.url} style={{display: 'inline-block', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {calendar.url}
                </span>

                <button className="imd-button imd-button-sm imd-button-outline imd-border-color-red imd-text-red" onClick={() => that.props.delete(config.apiAddress + '/app/calendar/calendars/' + key)}>
                    {TranslationService.trans(that.props.language, 'common.delete')}
                </button>

                <hr />
            </div>);
        });

        return rows;
    }

    changeRemindersSettings = (index) => {
        this.props.preloaderShow();
        let that = this;

        axios.patch(config.apiAddress + '/app/calendar/calendars/' + index)
            .then(function (response) {
                that.loadData();
            }).catch(function(error) {
                that.props.alert(TranslationService.trans(that.props.language, 'calendars.data_loading_error'));
                that.props.preloaderHide();
            });
    }

    /**
     * @returns {JSX.Element}
     */
    render() {
        return (
            <dialog className={'imd-dialog imd-bg-transparent-black' + (this.props.opened ? ' opened' : '')}>
                <div className="imd-dialog-body imd-bg-80-default imd-text-accent">
                    <header className="imd-text-contrast">
                        {TranslationService.trans(this.props.language, 'calendars.subscribed_calendars.title')}
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.props.close()} />
                    </header>

                    <div className="imd-dialog-content">
                        {this.renderCalendars()}
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => this.props.close()}>
                            {TranslationService.trans(this.props.language, 'common.close')}
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user)
    }
};

export const CalendarsDialog = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(CalendarsDialogClass);
