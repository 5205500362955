import React from "react";
import TranslationService from "../app/services/TranslationService";
import { connect } from "react-redux";
import NameService from "../app/services/NameService";

class HelloMessageClass extends React.Component {
    render = () => {
        if (this.props.user === null || this.props.user.name === null) {
            return (<span />);
        }

        let userName = this.props.user.name.split(' ')[0];

        let nameAlternativeForm = TranslationService.trans(this.props.language, '_alphabet');

        if (this.props.user.name_forms.hasOwnProperty(nameAlternativeForm)) {
            userName = this.props.user.name_forms[nameAlternativeForm];
        }

        let grammaticalFunctionName = TranslationService.trans(this.props.language, '_welcome_name_process_function');

        userName = NameService[grammaticalFunctionName](userName);

        return (<span>{TranslationService.trans(this.props.language, '_welcome', {'%message': userName})}</span>);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user)
    }
};

export const HelloMessage = connect(mapStateToProps, {})(HelloMessageClass);
