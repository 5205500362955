export const calendarScreen = (state = {dates: []}, action) => {
    switch (action.type) {
        case 'SCREEN_CALENDAR_DATES':
            return {
                dates: action.context
            };
        default:
            return state
    }
};
