import React from "react";
import { config } from "../app/config";

export class DeveloperInfo extends React.Component {
    render = () => {
        return (<div style={{maxWidth: '380px', position: 'fixed', top: 0, right: 0, color: '#aaa', textAlign: 'right', padding: '7px', fontSize: '0.8rem', display: (config.env === 'prod' ? 'none' : 'block')}}>
            Developer preview copy. All rights reserved.<br />
            Publication and distribution are strictly prohibited.<br />
            Publikacja oraz rozpowszechnianie surowo zabronione.<br />
            ENV: { config.env }
        </div>);
    }
}
