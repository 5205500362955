import React from 'react';
import '../../styles/elements/screen.scss';

class ScreenComponent extends React.Component {
    /**
     * @returns {{display: string}}
     */
    getStyle() {
        return {
            display: this.props.view === this.getScreenName() ? 'block' : 'none'
        }
    }
    /**
     * @returns {string}
     */
    getClass() {
        let className = 'screen screen-' + this.getScreenName();

        if (this.props.view === this.getScreenName()) {
            className += ' opened';
        }

        return className;
    }

    /**
     * @returns {string|null}
     */
    getScreenName() {
        return null;
    }
}

export default ScreenComponent;
