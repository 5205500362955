import React from 'react';
import axios from "axios";
import { config } from "../app/config";
import { DeveloperInfo } from "./DeveloperInfo";
import { SplashScreenContainer } from "./SplashScreenContainer";
import { LoginContainer } from './security/LoginContainer';
import { PreloaderContainer } from './Preloader';
import { AlertContainer } from "./Alert";
import { MainPanelContainer } from './MainPanel';
import { MainScreen } from './screens/MainScreen';
import { AutoScreenContainer } from "./screens/AutoScreenContainer";
import { SettingsScreenContainer } from './screens/SettingsScreenContainer';
import { NyumbaScreenContainer } from "./screens/NyumbaScreenContainer";
import { FinanceScreenContainer } from "./screens/FinanceScreen";
import { NoteScreen } from "./screens/NoteScreen";
import { connect } from "react-redux";
import Background from './backgrounds/default';
import '../styles/App.css';
import '../styles/icymat-design/dark.scss';
import '../styles/icymat-design/icymat-assistant.scss';
import TranslationService from "../app/services/TranslationService";
import {CalendarsScreenContainer} from "./screens/CalendarsScreen";
import {IntegrationsScreen} from "./screens/IntegrationsScreen";
import {AndraContainer} from "./Andra";
import {RecipesScreen} from "./screens/RecipesScreen";


export class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style: config.icymat_assistant ? 'icymat-assistant' : 'dark',
            notifications: {
                new: [],
                unread: []
            }
        };

        if (localStorage.getItem('identity') !== null) {
            axios.defaults.headers.common = {
                'X-AUTH-IDENTITY': localStorage.getItem('identity'),
                'X-AUTH-DEVICE': localStorage.getItem('device'),
                'X-AUTH-FINGERPRINT': localStorage.getItem('fingerprint')
            };
        } else if (localStorage.getItem('email') !== null) {
            axios.defaults.headers.common = {
                'X-AUTH-USER': localStorage.getItem('email'),
                'X-AUTH-DEVICE': localStorage.getItem('device'),
                'X-AUTH-FINGERPRINT': localStorage.getItem('fingerprint')
            };
        }

        this.loadNotifications();
        let that = this;
        setInterval(
            function() {
                that.refreshNotifications();
            },
            config.env === 'dev' ? 5000 : 60000
        );
    }

    refreshNotifications = () => {
        if (this.props.user === null || this.props.user.email === null) {
            return;
        }

        this.loadNotifications();
    }

    loadNotifications = () => {
        let that = this;
        axios.get(config.apiAddress + '/notifications')
            .then(function (response) {
                that.setState({notifications: response.data});
            }).catch(function(error) {
            });
    }

    render() {
        const parameters = window.location.href.split('#');
        if (parameters.length === 2 && parameters[1].substring(0, 12) === 'presentation') {
            let size = parameters[1].split('-');
            if (size.length === 1) {
                size = 40;
            } else {
                size = size[1];
            }

            return (
                <div className={"style-" + this.state.style + " " + this.props.direction} style={{direction: this.props.direction}}>
                    <AndraContainer scale={size} style={{margin: '50px auto'}} />
                    <Background />
                </div>
            );
        }

        return (
            <div className={"style-" + this.state.style + " " + this.props.direction} style={{direction: this.props.direction}}>
                <DeveloperInfo />
                <div>
                    <SplashScreenContainer />
                    <LoginContainer />
                    <PreloaderContainer />
                    <Background />
                    <AlertContainer />
                    <MainPanelContainer notifications={this.state.notifications} reloadNotificaions={this.loadNotifications} />
                    <AutoScreenContainer />
                    <IntegrationsScreen />
                    <SettingsScreenContainer />
                </div>

                <div id="screens">
                    <MainScreen />
                    <NoteScreen />
                    <CalendarsScreenContainer />
                    <NyumbaScreenContainer />
                    <FinanceScreenContainer />
                    <RecipesScreen />
                </div>

                <div style={{display: 'none'}}>
                    <h1>App info</h1>
                    Build: <span id="app_build_id">{ config.build }</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        fingerprint: state.fingerprint,
        direction: TranslationService.getTextDirection(state.user)
    }
};

const mapDispatchToProps = { };

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
