export const talkScreen = (state = {query: null, buffer: null, context: null}, action) => {
    switch (action.type) {
        case 'SCREEN_TALK_ASK':
            return {
                query: action.query,
                buffer: state.buffer,
                context: state.context
            };
        case 'SCREEN_TALK_SET_BUFFER':
            return {
                query: state.query,
                buffer: action.buffer,
                context: state.context
            };
        case 'SCREEN_TALK_SET_CONTEXT':
            return {
                query: state.query,
                buffer: state.buffer,
                context: action.context
            };
        default:
            return state
    }
};
