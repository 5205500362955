export const preloader = (state = false, action) => {
    switch (action.type) {
        case 'PRELOADER_SHOW':
            return true;
        case 'PRELOADER_HIDE':
            return false;
        default:
            return state
    }
};
