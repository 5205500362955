const initialState = {
    email: localStorage.getItem('email'),
    identity: localStorage.getItem('identity'),
    device: localStorage.getItem('device')
};

export const login = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                email: action.user.email,
                identity: action.user.identity,
                device: action.user.device
            };
        default:
            return state
    }
};
