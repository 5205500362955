import React from 'react';
import Bloobs from "./Bloobs";
import { connect } from "react-redux";
import '../styles/elements/preloader.scss';

export class Preloader extends React.Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    /**
     * @returns {{display: string}}
     */
    getStyle() {
        return {
            display: this.props.preloader ? 'block' : 'none'
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div id="preloader" className="imd-bg-transparent-default" style={this.getStyle()}>
                <Bloobs />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        preloader: state.preloader
    }
};
const mapDispatchToProps = {};

export const PreloaderContainer = connect(mapStateToProps, mapDispatchToProps)(Preloader);
