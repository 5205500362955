const initialState = localStorage.getItem('fingerprint') ? localStorage.getItem('fingerprint') : null;

export const fingerprint = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_FINGERPRINT':
            return action.fingerprint;
        default:
            return state
    }
};
