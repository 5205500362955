import React from 'react';
import { ReactMic } from '@cleandersonlobo/react-mic';
import axios from "axios";
import { config } from "../../../app/config";
import { Listening } from "../../preloaders/Listening";
import TranslationService from "../../../app/services/TranslationService";


export class ListenButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false
        }

        this.onStop = this.onStop.bind(this);
    };

    startRecording = () => {
        this.props.startListeningCallback();

        this.setState({
            record: true
        });

        let that = this;
        setTimeout(function () {
            that.stopRecording();
        }, 4000);
    }

    stopRecording = () => {
        this.setState({
            record: false
        })
    }

    onData = (recordedBlob) => {}

    onStop(recordedBlob) {
        this.props.startCatchTextFromMic();
        let callbackFunction = this.props.catchTextFromMic;
        let callbackErrorFunction = this.props.alert;

        var reader = new FileReader();
        let that = this;

        reader.onload = function() {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(',')[1];

            axios.post(config.apiAddress + '/speech/recognition', {base64: base64})
                .then(function (response) {
                    callbackFunction(response.data.message);
                    that.props.stopCatchTextFromMic();
                }).catch(function(error) {
                    callbackErrorFunction(TranslationService.trans(that.props.lang, 'main.recording_error'));
                    that.props.stopCatchTextFromMic();
                });
        };
        console.log(recordedBlob);
        reader.readAsDataURL(recordedBlob.blob);
    }

    render() {
        return (
            <div>
                <ReactMic
                    width={0}
                    height={0}
                    record={this.state.record}
                    className="sound-wave"
                    onStop={this.onStop}
                    onData={this.onData}
                    strokeColor="#000000"
                    backgroundColor="#ff0000" />
                <button
                    className="imd-bg-80-main imd-text-white"
                    onClick={this.startRecording}
                    type="button"
                    // style={{border: 0, bakcground: 'none', fontSize: '2.5rem'}}
                >
                    <span className="imd-icon imd-icon-mic" />
                </button>

                {/*<button*/}
                {/*    className="imd-bg-20-blue imd-text-white"*/}
                {/*    onClick={() => this.props.catchTextFromMic('pogoda')}*/}
                {/*    // type="button"*/}
                {/*>*/}
                {/*    <span className='imd-icon imd-icon-mug' />*/}
                {/*</button>*/}

                <div className="imd-bg-transparent-black" style={{width: '100vw', height: '100vh', position: 'fixed', zIndex: 100, bottom: 0, right: 0, marginRight: '-13px', display: (this.state.record ? 'block' : 'none')}}>
                    <div className="imd-bg-transparent-gray" style={{width: '250px', height: '250px', position: 'absolute', top: '50%', left:'50%', transform: 'translateX(-50%) translateY(-50%'}}>
                        <Listening style={{position: 'absolute', top: '50%', left:'50%', transform: 'translateX(-50%) translateY(-50%'}} />
                    </div>
                </div>
            </div>
        );
    }
}
