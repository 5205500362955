import { combineReducers } from "redux";
import { login } from "./login";
import { fingerprint } from "./fingerprint";
import { user } from "./user";
import { preloader } from "./preloader";
import { alert } from "./alert";
import { andra } from "./andra";
import { view } from "./view";
import { voice } from "./voice";
import { refreshStorage } from "./refreshStorage";
import { splashScreen } from "./splashScreen";
import { talkScreen } from "./screens/talk";
import { calendarScreen } from "./screens/calendar";

/**
 * This file concatenate all reducers from the app
 */
export default combineReducers({
    login,
    fingerprint,
    user,
    preloader,
    alert,
    andra,
    view,
    voice,
    refreshStorage,
    splashScreen,
    talkScreen,
    calendarScreen
});
