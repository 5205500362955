export const refreshStorage = () => ({
    type: 'REFRESH_STORAGE'
});

export const updateFingerprint = (fingerprint) => ({
    type: 'UPDATE_FINGERPRINT',
    fingerprint
});

export const login = (user) => ({
    type: 'LOGIN',
    user
});

export const userRefresh = (user) => ({
    type: 'USER_REFRESH',
    user
});

export const preloaderShow = (preloader) => ({
    type: 'PRELOADER_SHOW',
    preloader
});

export const preloaderHide = (preloader) => ({
    type: 'PRELOADER_HIDE',
    preloader
});

export const splashScreenHide = (preloader) => ({
    type: 'SPLASHSCREEN_HIDE',
    preloader
});

export const alert = (alert) => ({
    type: 'ALERT',
    alert
});

export const andraStateNormal = (andra) => ({
    type: 'ANDRA_STATE_NORMAL',
    andra
});

export const andraStateThinking = (andra) => ({
    type: 'ANDRA_STATE_THINKING',
    andra
});

export const setView = (view) => ({
    type: 'VIEW_SET',
    view
});

export const say = (text) => ({
    type: 'VOICE_SAY',
    text
});

/* Screens */
export const screenTalkAsk = (query) => ({
    type: 'SCREEN_TALK_ASK',
    query
});

export const screenTalkBuffer = (buffer) => ({
    type: 'SCREEN_TALK_SET_BUFFER',
    buffer
});

export const screenTalkContext = (context) => ({
    type: 'SCREEN_TALK_SET_CONTEXT',
    context
});

export const screenCalendarDates = (context) => ({
    type: 'SCREEN_CALENDAR_DATES',
    context
});
