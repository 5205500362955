import React from 'react';
import axios from 'axios';
import { preloaderShow, preloaderHide } from '../actions';
import { connect } from "react-redux";
import '../styles/elements/preloader.scss';
import TranslationService from "../app/services/TranslationService";

export class ConfirmationClass extends React.Component {
    /**
     * Constructor
     */
    constructor(props)
    {
        super(props);

        let type = 'api';
        if (this.props.type !== undefined) {
            type = this.props.type;
        }

        this.state = {
            address: this.props.address,
            type: type
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.address !== this.state.address) {
            this.setState({address: this.props.address});
        }
    }

    /**
     * @returns {*}
     */
    getClassName = () => {
        if (this.state.address !== null) {
            return 'imd-dialog imd-dialog-sm imd-bg-transparent-black opened';
        }

        return 'imd-dialog imd-dialog-sm imd-bg-transparent-black';
    };

    action = () => {
        let that = this;

        this.props.preloaderShow();
        axios.delete(this.state.address)
            .then(function (response) {
                that.setState({address: null});
                that.props.onSuccess(response, that.state.address);
                that.props.preloaderHide();
            }).catch(function(error) {
                that.setState({address: null});
                that.props.onError(error, that.state.address);
                that.props.preloaderHide();
            });
    };

    cancel = () => {
        this.setState({address: null});

        if (typeof this.props.onCancel !== 'undefined') {
            this.props.onCancel();
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={this.getClassName()}>
                <div className="imd-dialog-body imd-bg-80-default imd-text-accent">
                    <header>
                        {TranslationService.trans(this.props.language, 'common.delete')}
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.cancel()} />
                    </header>

                    <div className="imd-dialog-content">
                        {TranslationService.trans(this.props.language, 'common.actions.are_you_sure_to_delete_this_item')}
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => this.cancel()}>
                            {TranslationService.trans(this.props.language, 'common.no')}
                        </button>
                        <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => this.action()}>
                            {TranslationService.trans(this.props.language, 'common.yes')}
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        language: TranslationService.getLanguage(state.user)
    }
};

export const Confirmation = connect(mapStateToProps, {preloaderShow, preloaderHide})(ConfirmationClass);
