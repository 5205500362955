import React from 'react';
import '../../styles/preloaders/listening.scss';

export class Listening extends React.Component {
    render() {
        return (
            <div className="preloader listening" style={this.props?.style}>
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
                <div className="circle circle-3"></div>
                <div className="circle circle-4"></div>
                <div className="circle circle-5"></div>
            </div>
        )
    }
}
