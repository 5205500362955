export const jp_jp = {
    "_code": "jp",
    "_name": "\u65e5\u672c\u8a9e",
    "_direction": "ltr",
    "_welcome": "\u3053\u3093\u306b\u3061\u306f %message",
    "_national_days": "05-01\u300105-02\u300105-03\u300111-11\u300108-15\u30012023-10-15",
    "common": {
        "save": "\u4fdd\u5b58",
        "cancel": "\u30ad\u30e3\u30f3\u30bb\u30eb",
        "delete": "\u6d88\u53bb",
        "ok": "\u7d04",
        "actions": {"save_failed": "\u5909\u66f4\u3092\u4fdd\u5b58\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f"}
    },
    "login": {
        "imid_button": "IMid\u3067\u30ed\u30b0\u30a4\u30f3",
        "license": "\u30e9\u30a4\u30bb\u30f3\u30b9",
        "actions": {
            "failed": "\u30ed\u30b0\u30a4\u30f3\u306b\u5931\u6557\u3057\u307e\u3057\u305f",
            "relogin_required": "\u518d\u30ed\u30b0\u30a4\u30f3\u304c\u5fc5\u8981\u3067\u3059"
        }
    },
    "main": {
        "how_can_i_help_you": "\u3069\u3093\u306a\u5fa1\u7528\u3067\u3057\u3087\u3046\u304b\uff1f",
        "image_answer": "\u30b0\u30e9\u30d5\u30a3\u30ab\u30eb\u306a\u5fdc\u7b54",
        "actions": {"query_failed": "\u7533\u3057\u8a33\u3042\u308a\u307e\u305b\u3093\u304c\u3001\u7b54\u3048\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f\u3002"}
    },
    "menu": {"home": "\u30db\u30fc\u30e0\u753b\u9762", "settings": "\u8a2d\u5b9a"},
    "settings": {
        "tabs": {
            "account": "\u30a2\u30ab\u30a6\u30f3\u30c8",
            "devices": "\u30c7\u30d0\u30a4\u30b9",
            "app": "\u30a2\u30d7\u30ea",
            "license": "\u30e9\u30a4\u30bb\u30f3\u30b9"
        },
        "account": {
            "full_name": "\u59d3\u3068\u540d",
            "email_address": "\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9",
            "logout": "\u30ed\u30b0\u30a2\u30a6\u30c8",
            "andra": {
                "account": "\u30a2\u30f3\u30c9\u30e9\u306e\u30a2\u30ab\u30a6\u30f3\u30c8",
                "device": "\u30c7\u30d0\u30a4\u30b9",
                "device_name": "\u88c5\u7f6e\u540d"
            },
            "actions": {"logout": "\u30a2\u30d7\u30ea\u30b1\u30fc\u30b7\u30e7\u30f3\u304b\u3089\u6b63\u5e38\u306b\u30ed\u30b0\u30a2\u30a6\u30c8\u3057\u307e\u3057\u305f"}
        },
        "devices": {
            "connected_devices": "\u63a5\u7d9a\u3055\u308c\u305f\u30c7\u30d0\u30a4\u30b9",
            "device_name": "\u88c5\u7f6e\u540d",
            "device_id": "ID",
            "options": "\u30aa\u30d7\u30b7\u30e7\u30f3",
            "change_name": "\u540d\u524d\u306e\u5909\u66f4",
            "actions": {
                "device_removed": "\u30c7\u30d0\u30a4\u30b9\u304c\u524a\u9664\u3055\u308c\u307e\u3057\u305f",
                "device_remove_failed": "\u30c7\u30d0\u30a4\u30b9\u306e\u524a\u9664\u306b\u5931\u6557\u3057\u307e\u3057\u305f",
                "device_saved": "\u5909\u66f4\u304c\u4fdd\u5b58\u3055\u308c\u307e\u3057\u305f"
            }
        },
        "app": {
            "settings": "\u30a2\u30d7\u30ea\u30b1\u30fc\u30b7\u30e7\u30f3\u306e\u8a2d\u5b9a",
            "enable_voice": "\u97f3\u58f0\u5fdc\u7b54\u3092\u4f7f\u7528\u3059\u308b",
            "disable_voice": "\u97f3\u58f0\u5fdc\u7b54\u3092\u30aa\u30d5\u306b\u3059\u308b"
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "\u672a\u77e5",
            "home": "\u81ea\u5b85\u3067",
            "away": "\u5bb6\u306e\u5916\u3067",
            "manual": "\u30de\u30cb\u30e5\u30a2\u30eb",
            "silence": "\u9759\u304b\u306a"
        },
        "alarm": {
            "enter_code": "\u30a2\u30e9\u30fc\u30e0\u30b3\u30fc\u30c9\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044",
            "state": {
                "active": "\u30a2\u30af\u30c6\u30a3\u30d6",
                "waked_up": "\u8208\u596e\u3057\u305f",
                "run": "\u767a\u58f2\u958b\u59cb",
                "sleeping": "\u7761\u7720\u4e2d\u3067\u3059...",
                "disabling": "\u975e\u30a2\u30af\u30c6\u30a3\u30d6\u5316\u4e2d\u3067\u3059...",
                "inactive": "\u975e\u6d3b\u6027"
            },
            "actions": {
                "activate": "\u30a2\u30e9\u30fc\u30e0\u8d77\u52d5\u30b3\u30de\u30f3\u30c9\u304c\u9001\u4fe1\u3055\u308c\u307e\u3057\u305f",
                "activation_failed": "\u30a2\u30e9\u30fc\u30e0\u304c\u4f5c\u52d5\u3057\u307e\u305b\u3093\u3067\u3057\u305f",
                "send_command": "\u30b3\u30de\u30f3\u30c9\u304c\u30a2\u30e9\u30fc\u30e0\u306b\u9001\u4fe1\u3055\u308c\u307e\u3057\u305f",
                "send_command_failed": "\u30b3\u30de\u30f3\u30c9\u306e\u9001\u4fe1\u306b\u5931\u6557\u3057\u307e\u3057\u305f"
            }
        },
        "actions": {
            "send_command": "\u30b3\u30de\u30f3\u30c9\u304c\u9001\u4fe1\u3055\u308c\u307e\u3057\u305f",
            "data_failed": "\u30c7\u30fc\u30bf\u306e\u30ed\u30fc\u30c9\u306b\u5931\u6557\u3057\u307e\u3057\u305f",
            "send_failed": "\u30c7\u30fc\u30bf\u306e\u8ee2\u9001\u306b\u5931\u6557\u3057\u307e\u3057\u305f"
        }
    }
}