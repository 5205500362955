export default class NameService {
    static getDeDe(name, sex) {
        if (sex === 'female') {
            return 'Frau ' + name;
        }

        return 'Herr ' + name;
    }

    static getEn(name, sex) {
        if (sex === 'female') {
            return 'Ms. ' + name;
        }

        return 'Mr. ' + name;
    }

    static getPlPl(name, sex) {
        if (sex === 'female') {
            return 'Pani ' + name.slice(0, name.length - 1) + 'o';
        }

        //e, s, u,
        if (['sz', 'dz', 'ch', 'rz'].indexOf(name.slice(-2)) > -1) {
            return 'Panie ' + name + 'u';
        } else if (name.slice(-2) === 'ek') {
            return 'Panie ' + name.slice(0, name.length - 2) + 'ku';
        } else if (name.slice(-1) === 'a') {
            return 'Panie ' + name.slice(0, name.length - 1) + 'o';
        } else if (['b', 'f', 'm', 'n', 'p', 'w', 'z'].indexOf(name.slice(-1)) > -1) {
            return 'Panie ' + name + 'ie';
        } else if (['c', 'g', 'h', 'j', 'k', 'l'].indexOf(name.slice(-1)) > -1) {
            return 'Panie ' + name + 'u';
        } else if (name.slice(-1) === 'd') {
            return 'Panie ' + name + 'zie';
        } else if (name.slice(-1) === 'ł') {
            return 'Panie ' + name.slice(0, name.length - 1) + 'le';
        } else if (name.slice(-1) === 'r') {
            return 'Panie ' + name + 'ze';
        } else if (name.slice(-1) === 't') {
            return 'Panie ' + name.slice(0, name.length - 1) + 'cie';
        }

        //i, o
        return 'Panie ' + name;
    }

    static getUkUa(name, sex) {
        if (sex === 'female') {
            return 'пані ' + name;
        }

        return 'пане ' + name;
    }

    static default(name, sex) {
        return name;
    }
}
