import React from 'react';
import { connect } from 'react-redux';
import TranslationService from "../../../app/services/TranslationService";
import Bloobs from "../../Bloobs";
import AI from "../../AI";
import {config} from "../../../app/config";
import {AndraContainer} from "../../Andra";


export class TalkClass extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            messages: this.props.messages
        }
    }

    componentDidMount() {
        var objDiv = document.getElementById("system-talk-preview");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var objDiv = document.getElementById("system-talk-preview");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    renderMessages = () => {
        let messages = [];

        let that = this;
        let counter = this.state.messages.length - 1;
        this.state.messages.forEach(function(message, key) {
            messages.push(<div key={'q-' + key} className="imd-row message">
                <div className="imd-col-11 imd-offset-1 t-query">
                    <div className="imd-border-color-main imd-text-main">{message.query}</div>
                </div>
            </div>);

            if (message.answer !== null) {
                messages.push(<div key={'a-' + key} className="imd-row message">
                    <div className="imd-col-1" style={{textAlign: 'right'}}>
                        <AndraContainer scale={1} style={{margin: '0', display: key === counter ? 'block' : 'none' }} />
                    </div>
                    <div className="imd-col-10 t-answer">
                        <div className="imd-border-color-accent imd-text-accent">
                            <AI size={'1.1em'} visible={message.instruction === 'ai'} />
                            <span style={{ display: message.instruction === 'ai' ? 'inline' : 'none'}}>&nbsp;&nbsp;&nbsp;</span>
                            <span dangerouslySetInnerHTML={{ __html: message.answer.replaceAll("\n", '<br>') }} />

                            {
                                message.instruction === 'img' ?
                                    <div className={"imd-table-responsive"} style={{textAlign: 'center', marginTop: '10px'}}>
                                        <img
                                            src={config.apiAddress + '/image/' + message.data + '.png'}
                                            style={{maxWidth: '90vw', maxHeight: '250px'}}
                                            alt={TranslationService.trans(that.props.language, 'main.image_answer', that.props.user)}
                                        />
                                    </div>:
                                    ''
                            }

                            {
                                message.html !== null ?
                                    <div dangerouslySetInnerHTML={{ __html: message.html }} /> :
                                    ''
                            }
                        </div>
                    </div>
                </div>);
            }
        });

        return messages;
    }

    render() {
        return (
            <div className="imd-container-fluid talk" id="system-talk-preview" style={{height: this.props.height}}>
                {this.renderMessages()}

                <div style={{scale: 0.5}}>{this.props.thinking ? <Bloobs height={'50px'} /> : ''}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user)
    }
};

export const TalkScreen = connect(mapStateToProps, {})(TalkClass);
