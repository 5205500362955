import React from 'react';
import { connect } from 'react-redux';
import { AndraContainer } from "../../Andra";
import {config} from "../../../app/config";
import Bloobs from "../../Bloobs";
import TranslationService from "../../../app/services/TranslationService";
import {HelloMessage} from "../../HelloMessage";
import AI from "../../AI";


export class WelcomeClass extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            queryText: '',
            query: '',
            thinking: false,
            answer: '',
            instruction: null,
            data: null,
            html: null
        }
    }

    render() {
        return (
            <div>
                <div className='imd-hide-xs imd-hide-sm'>
                    <AndraContainer scale={5} style={{margin: '0 auto 50px auto'}} />
                </div>
                <div className='imd-hide-md imd-hide-lg imd-hide-xl'>
                    <AndraContainer scale={3} style={{margin: '0 auto 50px auto'}} />
                </div>

                {
                    this.props.query !== '' ?
                        <div className="imd-text-main query">
                            {this.props.query}
                            <AI size={'1.5em'} visible={this.props.instruction === 'ai'} />
                        </div> :
                        <span className="imd-text-main hello">
                        <HelloMessage />
                    </span>
                }

                {
                    this.props.thinking ?
                        <Bloobs /> :
                        ''
                }

                {
                    this.props.answer !== '' ?
                        <div className="imd-text-contrast answer">{this.props.answer}</div> :
                        ''
                }

                {
                    this.props.instruction === 'img' ?
                        <div className={"imd-table-responsive"} style={{textAlign: 'center'}}>
                            <img
                                src={config.apiAddress + '/image/' + this.props.data + '.png'}
                                style={{maxWidth: '90vw', maxHeight: '250px'}}
                                alt={TranslationService.trans(this.props.language, 'main.image_answer', this.props.user)}
                            />
                        </div>:
                        ''
                }

                {
                    this.props.html !== null ?
                        <div dangerouslySetInnerHTML={{ __html: this.props.html }} /> :
                        ''
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user)
    }
};

export const WelcomeScreen = connect(mapStateToProps, {})(WelcomeClass);
